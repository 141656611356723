export const staffTableConf = {
	"1": [
		{
			label: '部门名称',
			prop: '1'
		},
		{
			label: '上级部门',
			prop: '2'
		},
		{
			label: '部门人数',
			prop: '3'
		},
		{
			label: '备注',
			prop: '4'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	],
	"2": [
		{
			label: '部门名称',
			prop: 'name'
		},
		{
			label: '上级部门',
			prop: 'lastBm'
		},
		{
			label: '部门人数',
			prop: 'bmNUm'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	],
	"3": [
		{
			label: '登录账号',
			prop: 'username'
		},
		{
			label: '用户昵称',
			prop: 'username'
		},
		{
			label: '所属岗位',
			prop: 'name'
		},
		{
			label: '手机号码',
			prop: 'mobile'
		},
		{
			label: '操作',
			prop: 'operation'
		}
	]
}

export const staffFormConf = {
	"2": [
		{
			label: '部门名称',
			prop: 'bm',
			type: 'input'
		},
		{
			label: '父级部门',
			prop: 'lastBm',
			type: 'select'
		},
		{
			label: '备注',
			prop: '3',
			type: 'input'
		},
	],
	"1": [
		{
			label: '岗位名称',
			prop: '1',
			type: 'input'
		},
		{
			label: '招商数据权限',
			prop: '2',
			type: 'radio',
			options: [
				{
					label: '全部可见',
					value: 1
				},
				{
					label: '本人可见',
					value: 2
				}
			]
		},
		{
			label: '商品修改权限',
			prop: '3',
			type: 'radio',
			options: [
				{
					label: '接收',
					value: 1
				},
				{
					label: '不接收',
					value: 2
				}
			]
		},
		{
			label: '审核操作权限',
			prop: '4',
			type: 'checkboxGroup',
			options: [
				{
					label: '全部',
					value: 1
				},
				{
					label: '初审',
					value: 2
				},
				{
					label: '复审',
					value: 3
				},
				{
					label: '排期',
					value: 4
				},
				{
					label: '终审',
					value: 5
				},
				{
					label: '补播审核',
					value: 6
				}
			],
		},
		{
			label: '上级岗位',
			prop: 'operation1',
			type: 'select',
			options: []
		},
		{
			label: '所属部门',
			prop: 'operation2',
			type: 'select',
			options: []
		}
	],
	"3": [
		// {
		// 	label: '登录账号',
		// 	prop: '1',
		// 	type: 'input'
		// },
		// {
		// 	label: '密码',
		// 	prop: '2',
		// 	type: 'input'
		// },
		// {
		// 	label: '确认密码',
		// 	prop: '3',
		// 	type: 'input'
		// },
		// {
		// 	label: '用户姓名',
		// 	prop: '4',
		// 	type: 'input'
		// },
		// {
		// 	label: '用户昵称',
		// 	prop: 'operation',
		// 	type: 'input'
		// },
		{
			label: '手机号码',
			prop: 'mobile',
			type: 'input'
		},
		{
			label: '所属岗位',
			prop: 'operation5',
			type: 'select',
			options: []
		},
		// {
		// 	label: '电子邮箱',
		// 	prop: 'operation3',
		// 	type: 'input'
		// },
		// {
		// 	label: '性别',
		// 	prop: 'operation2',
		// 	type: 'select',
		// 	options: []
		// },
		// {
		// 	label: '商品联系人',
		// 	prop: 'operation1',
		// 	type: 'radio',
		// 	options: [
		// 		{
		// 			label: '可见',
		// 			value: 1
		// 		},
		// 		{
		// 			label: '不可见',
		// 			value: 2
		// 		}
		// 	]
		// }
	]
}

export const treeData = [{
	id: 1,
	label: '一级 1',
	children: [{
	id: 4,
	label: '二级 1-1',
	children: [{
		id: 9,
		label: '三级 1-1-1'
	}, {
		id: 10,
		label: '三级 1-1-2'
	}]
	}]
}, {
	id: 2,
	label: '一级 2',
	children: [{
	id: 5,
	label: '二级 2-1'
	}, {
	id: 6,
	label: '二级 2-2'
	}]
}, {
	id: 3,
	label: '一级 3',
	children: [{
	id: 7,
	label: '二级 3-1'
	}, {
	id: 8,
	label: '二级 3-2'
	}]
}]