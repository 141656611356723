<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
<!--			<el-tab-pane label="部门管理" name="1"></el-tab-pane>-->
			<el-tab-pane label="部门管理" name="2"></el-tab-pane>
			<el-tab-pane label="账号管理" name="3"></el-tab-pane>
		</el-tabs>
		<el-button type="primary" v-if="activeName == '2'" @click="addBm">新增</el-button>
		<el-button type="primary" v-else-if="activeName == '3'" @click="addPer">新增</el-button>
		<e-table ref="tables" :tableCols="staffTableConfItem" :dataOrigin="dataOrigin" :showPagination="false">
			<template #operation="scope" v-if="activeName == '2'">
				<el-button @click="editBm(scope.rowData.id)">编辑</el-button>
				<el-button @click="SetAuthRule(scope.rowData.id)">设置权限</el-button>
				<el-button type="danger" @click="DelAuth(scope.rowData.id)">删除</el-button>
			</template>
			<template #operation="scope" v-else-if="activeName == '3'">
				<el-button type="danger" @click="DelPer(scope.rowData.id)">删除</el-button>
			</template>
		</e-table>
		<e-dialog width="500px" v-if="activeName == '2'" ref="dialogRef" v-on:confirm="confirmBm">
			<e-form ref="formRef" :formConfList="formConfListItem" />
		</e-dialog>
		<e-dialog width="500px" v-else-if="activeName == '3'" ref="dialogRef" v-on:confirm="confirmPer">
			<e-form ref="formRef" :formConfList="formConfListItem" />
		</e-dialog>
		<e-dialog title="角色设置" width="300px" ref="dialogAuthRef" v-on:confirm="confirm">
			<el-tree
				:data="authRule"
				ref="tree"
				show-checkbox
				node-key="id"
				:default-checked-keys="checkedData">
				</el-tree>
		</e-dialog>
	</div>
</template>

<script>
import {staffTableConf, staffFormConf, treeData} from '@data/system/staff'
export default {
	name: 'Staff',
	data() {
		return {
			activeName: '2',
			staffTableConf,
			staffFormConf,
			treeData,
			dataOrigin: {
				data: []
			},
			authRule: [],
			checkedData: [],
			id: '',
			formConfListItem: [],
			bmList: [],
			bmAllList: [],
			type: '',
			editId: ''
		}
	},
	computed: {
		staffTableConfItem() {
			return this.staffTableConf[this.activeName]
		},
	},
	methods: {
		addPer(){
			this.formConfListItem = [
				{
					label: '手机号码',
					prop: 'mobile',
					type: 'input'
				},
				{
					label: '上级部门',
					prop: 'mid',
					type: 'select',
					options: this.bmAllList
				},
			];
			this.$refs.dialogRef.handleOpen()
		},
		editBm(id){
			let that = this
			this.editId = id
			this.type = 'edit'
			that.formConfListItem = []
			that.get('?server=auth.group.by.id.get',{id:id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.formConfListItem = [
					{
						label: '部门名称',
						prop: 'name',
						type: 'input'
					},
					{
						label: '父级部门',
						prop: 'pid',
						type: 'select',
						options: that.bmAllList
					},
					{
						label: '备注',
						prop: '3',
						type: 'input'
					},
				];
				that.$refs.formRef.formData = response.data
				that.$refs.formRef.formData.name = response.data.name
				that.$refs.formRef.formData.pid = response.data.pid ? response.data.pid : ''

				//这里只会在接口是失败状态返回，不需要去处理错误提示
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
			this.$refs.dialogRef.handleOpen()
		},
		addBm(){
			this.type = 'add'
			this.formConfListItem = [
				{
					label: '部门名称',
					prop: 'bm',
					type: 'input'
				},
				{
					label: '父级部门',
					prop: 'mid',
					type: 'select',
					options: this.bmAllList
				},
				{
					label: '备注',
					prop: '3',
					type: 'input'
				},
			];
			this.$refs.dialogRef.handleOpen()
		},
		confirmPer(){
			let that = this
			let formData = this.$refs.formRef.formData
			this.post('?server=auth.user.bind.handle.post',{mobile:formData.mobile,mid:formData.mid}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg)
				that.$refs.dialogRef.handleClose()
				that.formConfListItem = []
				that.$refs.formRef.formData.mobile = ''
				that.$refs.formRef.formData.mid = ''
				that.GetPerson()
				//这里只会在接口是失败状态返回，不需要去处理错误提示
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		confirmBm(){
			let that = this
			let formData = this.$refs.formRef.formData
			switch (this.type) {
				case 'add':
					this.post('?server=auth.user.group.push.handle.post',{title:formData.bm,mid:formData.mid,rid:0}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg)
						that.$refs.dialogRef.handleClose()
						that.formConfListItem = []
						// that.$refs.formRef.formData.bm = ''
						// that.$refs.formRef.formData.mid = ''
						that.GetDetail()
						//这里只会在接口是失败状态返回，不需要去处理错误提示
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case 'edit':
					this.post('?server=auth.user.group.edit.handle.post',{title:formData.name,mid:formData.pid,rid:0,id:this.editId}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg)
						that.$refs.dialogRef.handleClose()
						that.formConfListItem = []
						// that.$refs.formRef.formData.bm = ''
						// that.$refs.formRef.formData.mid = ''
						that.GetDetail()
						//这里只会在接口是失败状态返回，不需要去处理错误提示
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
			}

		},
		DelPer(id){
			let that = this
			this.$confirm('是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.get('?server=auth.user.un.bind.handle.post',{id:id}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message.success(response.data.msg)
					that.GetDetail()
					//这里只会在接口是失败状态返回，不需要去处理错误提示
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		DelAuth(id){
			let that = this
			this.$confirm('是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.get('?server=auth.user.group.del.handle.post',{id:id}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message.success(response.data.msg)
					that.GetDetail()
					//这里只会在接口是失败状态返回，不需要去处理错误提示
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
		confirm(){
			let that = this
			if(!that.id){
				that.$message.error('缺失id')
				return
			}

			let tree = this.$refs.tree;
			let array = tree.getCheckedKeys().concat(tree.getHalfCheckedKeys())

			let idStr = array.toString();

			this.post('?server=auth.user.auth.edit.handle.post',{id:that.id,rid:idStr}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg)
				that.$refs.dialogAuthRef.handleClose()
				//这里只会在接口是失败状态返回，不需要去处理错误提示
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		SetAuthRule(id){
			this.id = id
			this.GetAuth(id)
			this.$refs.dialogAuthRef.handleOpen()
		},
		handleClick() {
			this.$refs.tables.loading = true
			if(this.activeName == '2'){
				this.GetDetail()
			} else if (this.activeName == '3') {
				this.GetPerson()
			}
		},
		GetAuth(id){
			let that = this
			that.authRule = []
			this.get('?server=auth.user.rule.lists.get',{id:id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				that.authRule = response.data.authRule
				that.checkedData  = response.data.authMine
				//这里只会在接口是失败状态返回，不需要去处理错误提示
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetDetail(){
			let that = this
			that.dataOrigin.data = []
			that.$refs.tables && that.$refs.tables.initData()
			this.get('?server=auth.user.group.lists.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				let len = response.data.tree.length
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.tree[i])
				}
				that.$refs.tables.loading = false
				that.bmList = response.data.tree
				that.bmAllList = response.data.group
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetPerson(){
			let that = this
			that.dataOrigin.data = []
			that.$refs.tables && that.$refs.tables.initData()
			this.get('?server=auth.user.person.lists.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				let len = response.data.data.length
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
			this.GetDetail()
		});
	}
}
</script>